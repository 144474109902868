import { useState, useContext } from 'react'
import { useOverlay } from '../context/OverlayContext'
import { StripeComp } from './Stripe.component'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { solid } from '@fortawesome/fontawesome-svg-core/import.macro'
import Register from './Register.component'
import Auth from './Auth.component'
import CharityDetail from './Overlay.charity.component'
import CharityPayment from './Charity.payment.component'
import { UserContext } from '../context/UserContext'

type OverlayProps = {
  isOpen: boolean,
  component:string,
  data: any,
  option: any
}

const Overlay = ( { isOpen, component, data, option }:OverlayProps ) => {
  const userContext = useContext( UserContext )
  const [ showSignIn, setShowSignIn ] = useState( false )
  const { closeOverlay } = useOverlay()

  const startScroll = () => {
    document.body.classList.remove( "stop-scroll" )
  }

  const onComplete = ( res:any ) => {
    closeOverlay()
    startScroll()
  }

  return (
    <div
      className={ `overlay ${ isOpen ? "overlay-open" : "overlay-close" }` }
      >
      <div
        className='overlay-card'
        >
        <button
          className='close-btn'
          onClick={ closeOverlay } >
          <FontAwesomeIcon icon={ solid( 'xmark' ) } />
        </button>
        { component==='stripe' ? (
          <StripeComp { ...data }/>
        ) : component==='charity' ? (
          <CharityDetail />
        ) : component==='payment' ? (
          <CharityPayment />
        ) : (
          <section
            className='auth-section'
            >
            { userContext.user ? (
              <section>
                <h4>Let's not lose your hard work. Save now.</h4>
                <button
                  onClick={ onComplete }
                  >
                  Save your data
                </button>
              </section>
            ) : (
              showSignIn ? (
              <>
                <h4>Excellent,<br />give yourself a big hand!</h4><p>We have now calculated your quote. But let's not lose your hard work. Save now so you can retrieve and edit your quote at any time.</p>
                <Auth 
                  onComplete={ onComplete }
                  />
                <p
                  className='link'
                  onClick={ () => setShowSignIn( false ) }
                  >
                  Not registeded? Click here to register
                </p>
              </>
              ) : (
              <>
                <h4>Excellent,<br />give yourself a big hand!</h4><p>We have now calculated your quote. But let's not lose your hard work. Save now so you can retrieve and edit your quote at any time.</p>
                <Register 
                  onComplete={ onComplete } 
                  />
                <p
                  className='link'
                  onClick={ () => setShowSignIn( true ) }
                  >
                  Already registered? Click here to sign in
                </p>
              </>
              )
            ) }
          </section>
        ) }
      </div>
    </div>
  )
}

export default Overlay
