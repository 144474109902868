import React, { useContext, useState } from 'react'
import CharityData from '../types/Charity.type'
import { CharityContext } from '../context/CharityContext'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { solid, regular, brands, icon } from '@fortawesome/fontawesome-svg-core/import.macro'
import { useCart } from '../context/CartContext'

const OverlayCharity: React.FC = () => {
  const charityContext = useContext( CharityContext )
  const web = charityContext.charity?.charity_contact_web ? charityContext.charity?.charity_contact_web.replace( 'https://', '' ).replace( 'http://', '' ) : ''
  const iconUrl = 'https://www.google.com/s2/favicons?sz=256&domain=' + web
  const [ donateForm, setDonateForm ] = useState(false)
  const [ isSubscription, setSubscription ] = useState('true')
  const [ isGiftAid, setGiftAid ] = useState('false')
  const { isInCart, openCart, addToCart } = useCart()

  const Details = () => {
    const id = charityContext.charity?._id || ''
    return (
      <>
        <div
          className='charity-details'
          >
          <h2>
            { web != '' 
              ?
              <div
                className='charity-icon'
                style={ { backgroundImage:`url(${ iconUrl })` } }
                >
              </div>
              : ''
            }

           { charityContext.charity?.charity_name } </h2>
          <h3>Registered Charity Number: { charityContext.charity?.registered_charity_number }</h3>
          {  charityContext.charity?.charity_activities ?
              <>
                <h3>Our aims are:</h3>
                <p>{ charityContext.charity?.charity_activities }</p>
              </>
             : ''
          }
        </div>
        <button
          className='donate-button'
          >
          <FontAwesomeIcon
            className='heart'
            icon={ solid('heart') }
            onClick={ openCart }
            />
          &nbsp;
          Donate 
        </button> 
      </>
    )
  }

  return (
    <>
      { charityContext?.charity
        ?  <Details /> 
        : ""
      }
    </>
  )
}

export default OverlayCharity
