import React, { useState, useContext } from 'react'
import { userRegister } from '../services/Api'
import { UserContext } from '../context/UserContext'

interface Propdata {
  onComplete:any
}

const RegisterComponent: React.FC<Propdata> = ( { onComplete} ) => {
  const userContext = useContext( UserContext )
  const [ fname, setFname ] = useState( '' )
  const [ lname, setLname ] = useState( '' )
  const [ loadingState, setLoadingState ] = useState(false)
  const [ password, setPassword ] = useState( '' )
  const [ email, setEmail ] = useState( '' )
  const [ response, setResponse ] = useState({ status: 0, msg:'', error:'' })

  const onChange = ( e:any ) => {
    const target = e.target
    const value = target.value
    if( target.name === 'fname' ) setFname( value )
    if( target.name === 'lname' ) setLname( value )
    if( target.name === 'email' ) setEmail( value )
    if( target.name === 'password' ) setPassword( value )
  }

  const submit = ( e:any ) => {
    setLoadingState(true)
    if( !fname ) alert('Please enter first name')
    else if( !lname ) alert('Please enter last name')
    else if( !email ) alert('Please enter email')
    else if( !password ) alert('Please enter password')
    else {
      userRegister( fname, lname, email, password )
        .then( (results) => {
          const error = results.data.error
          let user = results.data.data
          setLoadingState(false)
          if( results.status === 200 ) {
            userContext.setUser( user )
            onComplete( user )
            setResponse( {
              status: 200,
              msg: results.data.msg, 
              error: error
            } )
          } else {
            setLoadingState(false)
            setResponse( { 
              status: results.status,
              msg: results.data.msg, 
              error: results.data.error
            } )
          }
        } )
    }
    e.preventDefault()
  }

  return (
    <>
    <form
      onSubmit={ submit }
      >
      <input 
        type='text'
        name='fname'
        value={ fname }
        onChange={ onChange }
        placeholder='First Name'
      />
      <input 
        type='text'
        name='lname'
        value={ lname }
        onChange={ onChange }
        placeholder='Last Name'
      />
      <input 
        type='email'
        name='email'
        value={ email }
        onChange={ onChange }
        placeholder='Email'
      />
      <input
        type='submit'
        value={ loadingState ? 'loading' : response.error == null ? 'Thank you' : 'Register' }
        />
      <p
        className={ `alert ${ response.error === null ? '': 'alert-error' }` }
        >
        { response.msg }
      </p>
    </form>
  </> 
  )
}

export default RegisterComponent
