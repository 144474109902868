import { useCompare } from '../context/ComparisonContex'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { solid } from '@fortawesome/fontawesome-svg-core/import.macro'

type compareProps = {
  isOpen: boolean,
}

const CompareComponent = ( { isOpen }: compareProps ) => {
  const { compare, closeCompare } = useCompare()
  return <>
    <div
      className={ `compare ${ isOpen ? "compare-open" : "compare-closed" }` }
      >
      <FontAwesomeIcon
        className='compare-close'
        icon={ solid('times') }
        onClick={ closeCompare }
        />
      <div
        className='compare-items'
        >
        { compare.map( ( item:any ) => ( '' )) }
        </div>
    </div>
  </>
}

export default CompareComponent
