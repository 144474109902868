import React, { useState, createContext, useContext } from "react"
import CompareItem from "../types/Compare.type"
import Compare from "../components/Compare.component"

type CompareContextType = {
  openCompare: () => void
  closeCompare: () => void
  addToCompare: ( id: string, charity_name: string, registered_charity_number: string ) => void
  removeFromCompare: ( id: string ) => void
  compareQty: number
  compare: CompareItem[]
  isInCompare: ( id: string ) => boolean
}

const CompareContext = createContext( {} as CompareContextType )

type CompareContextProviderProps = {
  children: React.ReactNode
}

export const useCompare = () => useContext( CompareContext )

export const CompareContextProvider = ( { children }: CompareContextProviderProps ) => {
  const [ compare, setCompare ] = useState<CompareItem[]>( [] ) 
  const [ isOpen, setIsOpen ] = useState( false )

  const compareQty = Compare.length

  const addToCompare = ( id: string, charity_name: string, registered_charity_number: string ) => {
    setCompare( ( currentItems:any ) => { 
      return currentItems.find( ( item:any ) => item.id === id ) == null 
      ? [ ...currentItems, { id, charity_name, registered_charity_number } ]
      : currentItems.map( ( item:any ) => item.id === id ? { ...item, charity_name, registered_charity_number } : item )
    } )
  }

  const removeFromCompare = ( id: string ) => {
    setCompare( ( currentItems:any ) => currentItems.filter( ( item:any ) => item.id !== id ) )
  }

  const openCompare = () => {
    setIsOpen( true )
  }

  const closeCompare = () => {
    setIsOpen( false )
  }

  return (
    <CompareContext.Provider value={ { 
      openCompare,
      closeCompare,
      addToCompare, 
      removeFromCompare,
      compareQty,
      compare,
      isInCompare: ( id: string ) => {
        return compare.some( ( item:any ) => item.id === id )
      }
    } } >
      { children }
      <Compare isOpen={ isOpen } />
    </CompareContext.Provider>
  )
}
