import React, { useState, useContext } from 'react'
import { useNavigate, Link } from 'react-router-dom'
import { userAuth } from '../services/Api'
import { UserContext } from '../context/UserContext'

interface Propdata {
  onComplete:any
}

const Auth: React.FC<Propdata> = ( { onComplete} ) => {
  const userContext = useContext( UserContext )
  const [ userEmail, setUserEmail ] = useState(null)
  const [ password, setPassword ] = useState(null)
  const navigate = useNavigate()

  if( userContext.user ) { 
    navigate( '/profile', { replace:false } ) 
  }

  const onChange = ( e: any ) => {
   e.preventDefault() 
    const target = e.target
    const name = target.name
    const value = target.value
    if( name === 'userEmail' ) setUserEmail(value)
    if( name === 'userPassword' ) setPassword(value)
  }

  const submit = (event: any) => {
    const target = event.target
    const submit = target.querySelector('input[type=submit]')
    if( !userEmail ) alert('Please enter email address')
    else if( !password ) alert('Please enter password')
    else {
      submit.value = 'loading'
      submit.disabled = true
      userAuth( userEmail, password )
      .then( ( results ) => {
        const now = new Date().getTime()
            , ttl = new Date(now + 2 * 60 * 60 * 1000).getTime()
            , error = results.data.error
        let user = results.data.data
        if( !user ) {
          submit.value = 'Enter'
          submit.disabled = false
          alert( error )  
        } else {
          user.expires = ttl
          userContext.setUser( user )
          navigate( "/profile", { replace:true } )
        }
      } )
    }
    event.preventDefault()
  }

  return (
    <>
      <form onSubmit={ submit } >
        <input
          type='email'
          onChange={ onChange }
          name='userEmail'
          className='user-email'
          placeholder='Email'
          />
        <input
          type='password'
          onChange={ onChange }
          name='userPassword'
          className='user-password'
          placeholder='Password'
          /> 
        <input type='submit' value='Enter' className='auth-btn' /> 
      </form> 
      <p className='descriptor'> 
        Not registered? 
        <Link
          to="/register"
          >
          Click here to register
        </Link>
      </p>
    </>
  )
}

export default Auth
