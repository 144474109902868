import React from 'react'
import './styles/reset.css'
import AppRouter from './AppRouter'
import Footer from './components/Footer.component'
import Intro from './components/Intro.component'
import './styles/App.scss'
import { CharityContextProvider } from './context/CharityContext'
import { UserContextProvider } from './context/UserContext'
import { CartContextProvider } from './context/CartContext'
import { ErrorContextProvider } from './context/ErrorContext'
import { OverlayContextProvider } from './context/OverlayContext'
import { CompareContextProvider } from './context/ComparisonContex'

const App: React.FC = () => {
  return (
    <div >
      <ErrorContextProvider>
        <CartContextProvider>
          <UserContextProvider >
            <CharityContextProvider >
              <OverlayContextProvider>
                <CompareContextProvider>
                  <Intro />
                  <AppRouter />
                  <Footer />
                </CompareContextProvider>
              </OverlayContextProvider>
            </CharityContextProvider>
          </UserContextProvider >
        </CartContextProvider>
      </ErrorContextProvider>
    </div>
  )
}

export default App
