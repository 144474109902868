import React from 'react'
import { Link } from 'react-router-dom'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { solid, regular, brands, icon } from '@fortawesome/fontawesome-svg-core/import.macro'
import { useCompare } from '../context/ComparisonContex'

const Footer: React.FC = () => {
  const { isInCompare, openCompare, addToCompare } = useCompare()

  return (
    <footer 
      className='footer'
      >
      <div className='compare-block'>
        <p 
          className='hero-compare'
          onClick={ openCompare }
          >
          Compare 
        </p>
        <p className='descriptor'> up to 3 charities at a time<br /> to make sure your money goes further</p>
      </div>
      <ul
        className='social-wrapper'
        >
        <li 
          className='facebook'
          >
          <a href='https://facebook.com' target='_blank'>
            <FontAwesomeIcon 
              icon={ brands( 'facebook-f' ) } 
              />
          </a>
        </li>
        <li 
          className='twitter'
          >
          <a href='https://twitter.com' target='_blank'>
            <FontAwesomeIcon 
              icon={ brands( 'x-twitter' ) } 
              />
          </a>
        </li>
        <li 
          className='instagram'
          >
          <a href='https://instagram.com' target='_blank'>
            <FontAwesomeIcon 
              icon={ brands( 'tiktok' ) } 
              />
          </a>
        </li>
      </ul>
    </footer>
  )
}

export default Footer
