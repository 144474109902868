import React, { useContext } from 'react'
import { CharityContext } from '../context/CharityContext'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { solid } from '@fortawesome/fontawesome-svg-core/import.macro'
import { useCart } from '../context/CartContext'
import { useOverlay } from '../context/OverlayContext'
import { UserContext } from '../context/UserContext'

const CharityPayment: React.FC = () => {
  const charityContext = useContext( CharityContext )
  const userContext = useContext( UserContext )
  const web = charityContext.charity?.charity_contact_web ? charityContext.charity?.charity_contact_web.replace( 'https://', '' ).replace( 'http://', '' ) : ''
  const iconUrl = 'https://www.google.com/s2/favicons?sz=256&domain=' + web
  const { isInCart, openCart, addToCart } = useCart()
  const { loadContent } = useOverlay()

  const openCheckout = () => {
    loadContent( 'stripe', {}, null )
  }

  const submitDonation = ( e:any ) => {
    const target = e.target
    const donation = target.donation
    const value = donation.value
    const subscription = target.subscription
    const id = charityContext.charity?._id || ''
    const charity_name = charityContext.charity?.charity_name || ''
    const charity_contact_web = charityContext.charity?.charity_contact_web || ''
    if( value && value > 0) addToCart( id, charity_name, charity_contact_web, value, subscription.checked )
    else alert('Please enter an amount')
    e.preventDefault()
  }

  const Details = () => {
    const id = charityContext.charity?._id || ''
    return (
      <>
        <div
          className='charity-details'
          >
          <h2>
            { web !== '' 
              ?
              <div
                className='charity-icon'
                style={ { backgroundImage:`url(${ iconUrl })` } }
                >
              </div>
              : ''
            }

           { charityContext.charity?.charity_name } </h2>
          <h3>Registered Charity Number: { charityContext.charity?.registered_charity_number }</h3>
          <form onSubmit={ submitDonation }>
            <div
              className='donation-group'
              >
              { isInCart(id) ? (  
                <>
                  <h2 className='donated'>
                    Thank you for your donation!
                  </h2>
                  <p
                    className='donated-link'
                    onClick={ openCart }
                    >
                    Please open the cart to review your donations or
                  </p>
                  <button
                    className="donate-button"
                    onClick={ openCheckout }
                    >
                    Checkout Now
                  </button>
                </>
              ) : ( 
                <>
                  <label>
                    <input 
                      type="tel" 
                      name="donation"
                      placeholder="Enter Amount"
                      />
                  </label>
                  <button
                    className="donate-button"
                    type="submit"
                    >
                    <FontAwesomeIcon
                      className='heart'
                      icon={ solid('heart') }
                      />&nbsp;
                    Donate Now
                  </button>
                <p>
                <label>
                  <input
                    type="checkbox"
                    name="gift aid"
                    defaultChecked={false}
                    />
                    I would like to add Gift Aid
                    { userContext && userContext.user ? 
                      ( '' ) : (
                        <span className='fly'>You must have an account and be signed in to take advantage of Gift Aid</span>
                    ) }
                </label>
                </p>
                <p>
                <label>
                  <input
                    type="checkbox"
                    name="subscription"
                    defaultChecked={true}
                    />
                  Make this a monthly donation
                </label>
                </p>
              </>
              ) }
            </div>
          </form>
        </div>
      </>
    )
  }

  return (
    <>
      { charityContext?.charity
        ?  <Details /> 
        : ""
      }
    </>
  )
}

export default CharityPayment
