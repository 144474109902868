import React, { createContext, useContext, useState } from 'react'
//import OverlayItem from "../types/Overlay.type"
import Overlay from "../components/Overlay.component"

type OverlayContextType = {
  openOverlay: () => void
  closeOverlay: () => void
  loadContent: ( type:string, data:any, option:any ) => void
}

const OverlayContext = createContext( {} as OverlayContextType )

type OverlayContextProviderProps = {
  children: React.ReactNode
}

export const useOverlay = () => useContext( OverlayContext )

export const OverlayContextProvider = ( { children }: OverlayContextProviderProps ) => {
  const [ isOpen, setIsOpen ] = useState( false )
  const [ component, setComponent ] = useState( '' )
  const [ data, setData ] = useState( {} )
  const [ optionData, setOptionData ] = useState( {} )

  const stopScroll = () => {
    document.body.classList.add( "stop-scroll" )
  }

  const resumeScroll = () => {
    document.body.classList.remove( "stop-scroll" )
  }

  const loadContent = ( contentType:string, componentData:any, option:any ) => {
    setIsOpen(true)
    setComponent(contentType)
    setData( componentData )
    setOptionData( option )
    stopScroll()
  }

  const openOverlay = () => {
    setIsOpen( true )
  }

  const closeOverlay = () => {
    resumeScroll()
    setIsOpen( false )
  }

  return (
    <OverlayContext.Provider value={ {
      openOverlay,
      closeOverlay,
      loadContent
      } } >
      { children }
      <Overlay isOpen={ isOpen } component={ component } data={ data } option={ optionData } />
    </OverlayContext.Provider>
  )
}

