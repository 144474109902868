import React, { useState } from 'react'
import { useCart } from '../context/CartContext'
import { Elements, CardElement, useStripe, useElements } from '@stripe/react-stripe-js'
import { loadStripe } from '@stripe/stripe-js'
import { getClientSecret } from '../services/Api'
import "../styles/Stripe.css"

const StripeComp: React.FC = () => {
  const stripe = loadStripe( 'pk_test_51OJaMLBkoUovfbm4hknEii0rL9jmk9N8bGy5s6nG17TRItwvc3AZ5X54U3F6gHDmQBAYxy8j2zqbqeSVq26Pix8e00WMnflhSE' )

  return (
    <Elements stripe={stripe}>
      <CheckoutForm />
    </Elements>
  )
}

const CheckoutForm = () => {
  const [ isPaymentLoading, setPaymentLoading ] = useState(false)
  const { cartTotal } = useCart()
  const stripe = useStripe()
  const elements = useElements()
  const submit = (e:any) => {
    e.preventDefault()
    if( !stripe || !elements ) return
    setPaymentLoading(true)
    getClientSecret( cartTotal )
    .then( ( clientSecret: any ) => {
      const cardElement = elements.getElement(CardElement)
      if( cardElement ) {
        stripe.confirmCardPayment( clientSecret, {
          payment_method: {
            card: cardElement,
            billing_details: {
              name: "Jason Ellis"
            }
          }
        } )
        .then( ( paymentResult: any ) => {
          setPaymentLoading(false)
          console.log('paymentResult', paymentResult )
          if( paymentResult.paymentIntent.status === 'succeeded' ) { alert( 'success' ) }
          else alert( paymentResult.error.message )
        } )
      }
    } ).catch( ( error ) => console.error( error ) )
  }
  return (
    <div
      style={{
        padding: "3rem",
      }}
    >
      <div
        style={{
          maxWidth: "500px",
          margin: "0 auto",
        }}
      >
        <form
          style={{
            display: "block",
            width: "100%",
          }}
          onSubmit = {submit}
        >
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
            }}
          >
            <CardElement
              className="card"
              options={{
                style: {
                  base: {
                    backgroundColor: "white"
                  }
                },
              }}
            />
            <button
              className="pay-button"
              disabled={isPaymentLoading}
            >
              {isPaymentLoading ? "Loading..." : "Pay"}
            </button>
          </div>
        </form>
      </div>
    </div>
  )
}

export { StripeComp }
